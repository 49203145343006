
// Body
$body-bg: #ffffff;

$psa_navy: #1D2235;
$psa_blue: #0778A8;
$psa_text: #ffffff;
$psa_brown: #AAA298;

$rand_grey: #F2F2F2;
$rand_blue: #042452;
$rand_text: #4A4A4A;
$rand_orange: #FF5D26;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #AAA298;
$brand-warning: #0778A8;
$brand-danger: #E8463E;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Arial", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
