
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/select2/dist/css/select2.min";

.form-inline-block {
	display: inline-block;
}

body {
	height: 100vh;
	&.login-page {
		background-image: url('../images/bg.png');
		background-position: 50% 100%;
		background-size: cover;
	}
}

h1 {
	font-weight: bold;
	font-size: 16px;
	color: $psa_navy;
	margin-bottom: 30px;
}
h3 {
	font-size: 16px;
	color: $psa_navy;
	margin: 20px 0px;
}

.navbar-default {
	background: $psa_navy;
	border:none;

	.navbar-brand {
		padding: 20px 0px;
		height: auto;
		> img {
			display: inline;
		}
		> span {
			display: inline;
			color: $psa_text;
			font-weight: bold;
			margin-left: 20px;
		}
	}

	.navbar-nav {
		> li {
			> a {
				padding-top: 0px;
				padding-bottom: 0px;
				line-height: calc(100px - 16px);
				color: $psa_text;
				&:hover {
					color: $psa_blue;
				}
			}
		}
	}
}

.required {
	label {
		&::after {
			content: '*';
			font-size: 24px;
			line-height: 10px;
			color: red;
		}
	}
}

.btn-primary {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background: $psa_blue;
    border-radius: 5px;
    padding: 10px;
    border-color: $psa_blue;
    max-width: 200px;
	margin: 0 auto;
	&:hover {
		background: darken($psa_blue, 10%);
	}
}

.login-page {
	h1{ 
		margin-top: 50px;
		font-weight: bold;
		font-size: 22px;
		color: $psa_navy;
		text-align: center;
	}
	h2 {
		font-size: 16px;
		color: $psa_navy;
		text-align: center;
	}
	label {
		color: $psa_navy;
	}	
	.digits {
		display: block;
		width: calc(100% - 30px);
		background: rgba(255, 255, 255, 0.6);
		float: left;
		padding: 20px;
		margin-top: 10px;
		margin-bottom: 40px;
		margin-left: 15px;
		text-align: center;
		input.form-control {
			max-width: 50px;
			text-align: center;
			padding: 0px;
			margin: 0 auto;
		}
	}
}

.flipbook-index {

	.card {
		text-align: center;
		img {
			background: $psa_brown;
		}
		.btn {
			padding: 10px 30px;
		}
	}
}

table.table {
	thead {
		th {
			background: $psa_navy;
			color: $psa_text;
			border-bottom: 0px;
		}
	}
	tbody {
		td {
			border: 0px;
		}
	}
	&.table-striped > tbody > tr:nth-of-type(odd) {
    	background-color: #eaeaea;
	}
}

.form-holder {
	background-color: #eaeaea;
	padding: 20px;
}


.robinsday {
	.navbar-default {
		background: $rand_grey;
		.navbar-brand {
			> span {
				color: $rand_text;
			}
		}
		.navbar-nav {
			> li {
				> a {
					color: $rand_text;
					&:hover {
						color: $rand_orange;
					}
				}
			}
		}
	}

	.btn-primary {
		background: $rand_orange;
		border-color: $rand_orange;
		&:hover {
			background: darken($rand_orange, 10%);
		}
	}

	.login-page {
		h1{ 
			color: $rand_text;
		}
		h2 {
			color: $rand_text;
		}
		label {
			color: $rand_text;
		}	
	}

	.flipbook-index {
		h1 {
			color: $rand_text;
		}
		h3 {
			color: $rand_text;
		}
		.card {
			img {
				background: $rand_blue;
			}
		}
	}
}